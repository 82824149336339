body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.row-no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  }
.centered-last {
  margin-left: 0;
  flex: center;
  justify-content: center;
  align-items: center;
}
.floating-button {
  position: fixed;
  bottom: calc(20px + env(safe-area-inset-bottom)); /* Ensures it accounts for safe area on iPhone */
  right: calc(20px + env(safe-area-inset-right)); 
  z-index: 1000; /* Make sure it stays on top of other elements */
  display: flex;
}

.floating-button button {
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  border: none;
  border-radius: 50%;
  width: 60px; /* Button size */
  height: 60px; /* Button size */
  font-size: 24px; /* Font size */
  cursor: pointer;
  display: flex; /* Make the button a flex container */
  align-items: center; /* Center icon vertically */
  justify-content: center; /* Center icon horizontally */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for floating effect */
  padding: 0; /* Remove padding to avoid shifting */
}

.floating-button .fa-whatsapp {
  margin: 0; /* Remove margins to ensure proper centering */
  padding: 0; /* Remove any padding */
  line-height: 1; /* Ensure proper alignment */

}

.portfolio {
  max-height: 500 !important;
}
.portfolio
.floating-button button:hover {
  background-color: #0056b3; /* Button hover color */
}
.floating .fa-whatsapp {
  margin-right: 30px; /* Set the right margin for the icon */
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allows items to wrap into a new row */
}
.service-desc {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .col-md-3 {
    flex: 0 0 25%; /* Ensure 4 columns in larger screens */
    max-width: 25%;
  }
}
#photos {
  /* Prevent vertical gaps */
  line-height: 0;
  -webkit-column-count: 4;
  -webkit-column-gap: 0px;
  -moz-column-count: 5;
  -moz-column-gap: 0px;
  column-count: 4;
  column-gap: 10px;
}

#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: fit-content !important;
  object-fit: cover;
}

@media (max-width: 1200px) {
  #photos {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}

@media (max-width: 1000px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 800px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 400px) {
  #photos {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

